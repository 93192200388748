import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Get Truckers List */
function* getTrucksList(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/trucker-list-lane', 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({ type: 'truckers', payload: { model: 'truckers', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Equipment List */
function* getTruckEquipmentList(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/equipment-manager/equipment-list');
        if (res.status) {
            yield put({ type: 'truck_equipments', payload: { model: 'truck_equipments', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Create Truck User */
function* createTruckUser(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/add-trucker', 'POST', { data: payload });
        if (res.status) {
            toastr(res.data, 'success');
            // yield put({ type: ActionTypes.LOAD_DEFAULT_ADD_ALL_SUCCESS, payload: { model: 'truckers', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Truck User */
function* updateTruckUser(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/user-manager/update-trucker/${payload?._id}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr(res.data, 'success');
            // yield put({ type: ActionTypes.LOAD_DEFAULT_ADD_ALL_SUCCESS, payload: { model: 'truckers', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Truck User Location */
function* updateTruckUserLocation(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/user-manager/update-trucker-location/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            // yield put({ type: ActionTypes.LOAD_DEFAULT_ADD_ALL_SUCCESS, payload: { model: 'truckers', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Truck Detail by ID */
function* getTruckerDetailById(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/user-manager/my-trucker-detail/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Remove Truck User */
function* removeTruckUser(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const state: any = yield select();
        const res: ReturnType<any> = yield call(api, `/v1/api/user-manager/remove-trucker/${payload?._id}`, 'DELETE');
        if (res.status) {
            const body = {
                limit: state?.default?.truckers?.limit,
                page: state?.default?.truckers?.page,
            };
            yield put({
                type: ActionTypes.GET_DEFAULT_DATA_MODEL,
                payload: { pathname: `user-manager/my-trucker-list`, model: 'truckers', ...body },
            });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Truck Driver Information */
function* updateDriverStatus(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/user-manager/trucker-update-list`, 'POST', {
            data: payload,
        });
        if (res.status) {
            // yield put({ type: ActionTypes.LOAD_DEFAULT_ADD_ALL_SUCCESS, payload: { model: 'truckers', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.TRUCKS_GET_LIST, withCallback(getTrucksList as any));
    yield takeLatest(ActionTypes.TRUCKS_GET_EQUIPMENT_LIST, withCallback(getTruckEquipmentList as any));
    yield takeLatest(ActionTypes.TRUCKS_CREATE_USER, withCallback(createTruckUser as any));
    yield takeLatest(ActionTypes.TRUCKS_UPDATE_USER, withCallback(updateTruckUser as any));
    yield takeLatest(ActionTypes.TRUCKS_UPDATE_USER_LOCATION, withCallback(updateTruckUserLocation as any));
    yield takeLatest(ActionTypes.TRUCKS_GET_USER_DETAIL, withCallback(getTruckerDetailById as any));
    yield takeLatest(ActionTypes.TRUCKS_REMOVE_USER, withCallback(removeTruckUser as any));
    yield takeLatest(ActionTypes.TRUCKS_UPDATE_INFORMATION, withCallback(updateDriverStatus as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
