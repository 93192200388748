import { useReducer } from 'react';
interface InitalState {
    [key: string]: any;
}
const initialState: InitalState = {
    showChat: false,
    currentTruck: null,
};
interface Action {
    type: string;
    data: InitalState;
}
function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, ...action.data };
        default:
            return state;
    }
}

export const useChatState = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return { state, dispatch };
};
