import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Get User Profile Data */
function* getProfileUserDetail(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/get-user-detail');
        if (res.status) {
            yield put({ type: ActionTypes.SET_USER, payload: { model: 'profileDetail', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update User Profile Data */
function* updateProfileUserDetail(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/update-user', 'POST', { data: payload });
        if (res.status) {
            yield put({ type: ActionTypes.SET_USER, payload: { model: 'profileDetail', data: res.data } });
            toastr('Profile Updated Successfully', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update User Profile Password */
function* updateProfileUserPassword(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/change-password', 'POST', { data: payload });
        if (res.status) {
            toastr(res.data, 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Update User Profile Password */
function* readNotification(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/read-notification');
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.PROFILE_GET_USER_DETAIL, withCallback(getProfileUserDetail as any));
    yield takeLatest(ActionTypes.PROFILE_READ_NOTIFICATION, withCallback(readNotification as any));
    yield takeLatest(ActionTypes.PROFILE_UPDATE_USER_DETAIL, withCallback(updateProfileUserDetail as any));
    yield takeLatest(ActionTypes.PROFILE_UPDATE_USER_PASSWORD, withCallback(updateProfileUserPassword as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
