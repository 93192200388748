import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors } from '@/utils/helpers';

/* Create Fleet  User Address */
function* sendPushNotification(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/subscribe', 'POST', { data: payload });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.WEB_PUSH_SEND_MESSAGE, withCallback(sendPushNotification as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
