import { all, fork } from 'redux-saga/effects';

import DefaultSaga from './default.saga';

import DashboardSaga from '@/pages/Dashboard/saga';
import AuthSaga from '@/pages/Auth/saga';
import ProfileSaga from '@/pages/Profile/saga';
import TrucksSaga from '@/pages/Trucks/saga';
import ContactUsSaga from '@/pages/ContactUs/saga';
import FavouriteSaga from '@/pages/Favourites/saga';
import AddressSaga from '@/pages/Address/saga';
import SearchTruckSaga from '@/pages/SearchTruck/saga';
import LoadsSaga from '@/pages/Loads/saga';
import CalendarSaga from '@/pages/Calendar/saga';
import OrderSaga from '@/pages/Orders/saga';
import QuoteSaga from '@/pages/Quotes/saga';
import WebPushSaga from '@/components/WebPush/saga';
import SearchLoadSaga from '@/pages/SearchLoad/saga';
import DeliveryScheduleSaga from '@/pages/DeliverySchedule/saga';
import PurchaseOrdersSaga from '@/pages/PurchaseOrders/saga';
const sagas = [
    DefaultSaga,
    DashboardSaga,
    AuthSaga,
    ProfileSaga,
    TrucksSaga,
    ContactUsSaga,
    FavouriteSaga,
    AddressSaga,
    SearchTruckSaga,
    LoadsSaga,
    WebPushSaga,
    OrderSaga,
    QuoteSaga,
    CalendarSaga,
    SearchLoadSaga,
    DeliveryScheduleSaga,
    PurchaseOrdersSaga,
];

export default function* rootSaga() {
    yield all(sagas.map(saga => fork(saga)));
}
