import React, { Suspense, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { Bars } from 'react-loader-spinner';
import { LoadScript } from '@react-google-maps/api';

import { useAuth, useSettings } from '@/components/App';
import { AppProvider } from '@/components/App';
import store from '@/store';
import { logout } from '@/utils/helpers';

interface AUTHROUTE {
    children: JSX.Element;
}

export const AuthRoute = ({ children }: AUTHROUTE) => {
    const location = useLocation();
    const { user } = useAuth();
    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
};

interface LOADLAZY {
    label: string;
    path: string;
    auth: boolean;
    component: React.LazyExoticComponent<any>;
}
type Libraries = ('places' | 'geometry' | 'drawing' | 'localContext' | 'visualization')[];
export const LoadLazy = (route: LOADLAZY) => {
    const settings = useSettings();
    const [libraries] = useState(['places', 'geometry'] as Libraries);
    return (
        <LoadScript
            id={route.label}
            googleMapsApiKey={settings.googleAPIKey}
            libraries={libraries}
            loadingElement={<div className="text-center" style={{ marginTop: '20%' }}></div>}
        >
            <Suspense
                fallback={
                    <div className="d-flex justify-content-center" style={{ marginTop: '25%' }}>
                        <Bars
                            height="50"
                            width="50"
                            color="#3299cc"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                }
            >
                <AppProvider>
                    {route.auth ? (
                        <AuthRoute>
                            <route.component title={route.label} />
                        </AuthRoute>
                    ) : (
                        <route.component />
                    )}
                </AppProvider>
            </Suspense>
        </LoadScript>
    );
};

export const validateAuthentication = () => {
    const jwtDecodedToken = Cookies.get('token') as undefined | string;
    if (jwtDecodedToken) {
        // Non Null Assertion ! will remove undefined and null from a type without doing any explicit type checking
        const decoded: any = jwt_decode(jwtDecodedToken as string);
        store.dispatch({ type: 'SET_USER', payload: { model: 'user', data: decoded } });

        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            store.dispatch({ type: 'REMOVE_USER', payload: { model: 'user', data: null } });
            logout();
            return false;
        }
        return true;
    }
};
