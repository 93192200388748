import { useSelector } from 'react-redux';

// import { reducer, initialState } from './reducer';

export const useAuth = () => {
    //   const [state, dispatchState] = useReducer(reducer, initialState);
    const state = useSelector(state => state) as any;
    const { auth } = state;
    if (auth?.user?._id) {
        return { user: auth.user._id, ...state };
    } else {
        return {
            user: null,
        };
    }
};
