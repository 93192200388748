import { useCallback, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import { reducer, initialState } from './reducer';

export const useRequest = () => {
    const [state, dispatchState] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const request = useCallback(async (type: string, payload?: unknown) => {
        // Set Loading to True
        return new Promise((resolve, reject) => {
            dispatchState({
                type: 'SET_DATA',
                data: {
                    ...state,
                    loading: {
                        [type + '_LOADING']: true,
                    },
                },
            });
            // Execute API Call
            const request = {
                type,
            };
            if (payload) {
                request['payload'] = payload;
            }
            dispatch({
                ...request,
                onComplete: ({ error, cancelled, data }: { data: unknown; error: unknown; cancelled: unknown }) => {
                    try {
                        dispatchState({
                            type: 'SET_DATA',
                            data: {
                                ...state,
                                loading: {
                                    [type + '_LOADING']: false,
                                },
                                error,
                                cancelled,
                                data,
                            },
                        });
                        return resolve(data);
                    } catch (error) {
                        reject(error);
                    }
                },
            });
        });
    }, []);
    return {
        request,
        ...state,
    };
};
