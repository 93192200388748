import ActionTypes from '../types/index';
type InitialState = {
    defaultGetData: Array<unknown>;
    truckers: Array<unknown>;
    allusers: Array<unknown>;
    truck_equipments: Array<unknown>;
    favouriteUsers: Array<unknown>;
    unfavouriteUsers: Array<unknown>;
    searchTruckUsers: Array<unknown>;
    searchlaneslist: Array<unknown>;
    nearbyloadtrucks: Array<unknown>;
    availabletrucksforload: Array<unknown>;
    searchloadfilter: Array<unknown>;
    top5ratedtrucks: Array<unknown>;
    truckersnotontime: Array<unknown>;
    recentactivities: Array<unknown>;
    popularroutes: Array<unknown>;
    pastloadsbylane: Array<unknown>;
    currentloadsbylane: Array<unknown>;
    allloadsDetails: Array<unknown>;
    alldeliveryschedule: Array<unknown>;
    quotessend: Array<unknown>;
    quotesreceive: Array<unknown>;
    quotesapproved: Array<unknown>;
    quotesdeclined: Array<unknown>;
    pendingloads: Array<unknown>;
    delayedloads: Array<unknown>;
    riskdelayedloads: Array<unknown>;
    acceptedloads: Array<unknown>;
    activeloads: Array<unknown>;
    assignloads: Array<unknown>;
    billedloads: Array<unknown>;
    historyloads: Array<unknown>;
    addresses: Array<unknown>;
    availablePalletsAtWarehouse: unknown;
    availableProductsAtWarehouse: unknown;
    availableSAPProducts: unknown;
    latestOrder: Array<unknown>;
    lanes: Array<unknown>;
    wareHouses: Array<unknown>;
    loads: Array<unknown>;
    purchaseorders: Array<unknown>;
    quotescreated: Array<unknown>;
    loading: boolean;
    error: boolean;
    errors: unknown;
    modal: boolean;
    getGraphActivity: unknown;
    reportListByCustomer: Array<unknown>;
    reportListByCarrier: Array<unknown>;
    reportListByLanes: Array<unknown>;
    reportListByDate: Array<unknown>;
    carrier: Array<unknown>;
    neworder: Array<unknown>;
    containers: Array<unknown>;
    weekContainers: Array<unknown>;
    monthContainers: Array<unknown>;
    newPurchaseOrders: Array<unknown>;
    activePurchaseOrders: Array<unknown>;
    closedPurchaseOrders: Array<unknown>;
};

const initialState: InitialState = {
    defaultGetData: [],
    truckers: [],
    allusers: [],
    truck_equipments: [],
    favouriteUsers: [],
    unfavouriteUsers: [],
    searchlaneslist: [],
    searchTruckUsers: [],
    nearbyloadtrucks: [],
    availabletrucksforload: [],
    searchloadfilter: [],
    top5ratedtrucks: [],
    truckersnotontime: [],
    recentactivities: [],
    popularroutes: [],
    pastloadsbylane: [],
    currentloadsbylane: [],
    allloadsDetails: [],
    alldeliveryschedule: [],
    quotessend: [],
    quotesreceive: [],
    quotesapproved: [],
    quotesdeclined: [],
    pendingloads: [],
    delayedloads: [],
    riskdelayedloads: [],
    acceptedloads: [],
    activeloads: [],
    assignloads: [],
    billedloads: [],
    historyloads: [],
    addresses: [],
    latestOrder: [],
    lanes: [],
    wareHouses: [],
    loads: [],
    purchaseorders: [],
    quotescreated: [],
    loading: true,
    error: false,
    errors: {},
    availablePalletsAtWarehouse: {},
    availableProductsAtWarehouse: {},
    availableSAPProducts: [],
    getGraphActivity: {},
    reportListByCustomer: [],
    reportListByCarrier: [],
    reportListByLanes: [],
    reportListByDate: [],
    carrier: [],
    neworder: [],
    containers: [],
    weekContainers: [],
    monthContainers: [],
    newPurchaseOrders: [],
    activePurchaseOrders: [],
    closedPurchaseOrders: [],
    modal: false,
};

interface Data {
    _id: string;
}
interface Action {
    payload: {
        model: string;
        data: Array<unknown> | string | Data | any;
    };
    type: string;
}
const defaultReducer = (state = initialState, action: Action) => {
    const { payload } = action;

    if (action.type === ActionTypes.CLEAR_ADDRESSES) {
        return { ...state, addresses: [] };
    }

    if (action.type === ActionTypes.CLEAR_PALLETS) {
        return { ...state, availablePalletsAtWarehouse: {} };
    }

    if (action.type === ActionTypes.CLEAR_AVAILABLE_PRODUCTS) {
        return { ...state, availableProductsAtWarehouse: {} };
    }

    switch (action.type) {
        // Single Add
        case ActionTypes.LOAD_DEFAULT_ADD_ALL_SUCCESS:
            return {
                ...state,
                [payload.model]: [...state[payload.model], payload.data],
                loading: false,
                modal: false,
            };
        // Multiple Cases to Store All Data for Models
        case 'defaultGetData':
        case 'truckers':
        case 'allusers':
        case 'truck_equipments':
        case 'favouriteUsers':
        case 'unfavouriteUsers':
        case 'searchlaneslist':
        case 'searchTruckUsers':
        case 'nearbyloadtrucks':
        case 'availabletrucksforload':
        case 'searchloadfilter':
        case 'top5ratedtrucks':
        case 'truckersnotontime':
        case 'recentactivities':
        case 'popularroutes':
        case 'pastloadsbylane':
        case 'currentloadsbylane':
        case 'allloadsDetails':
        case 'alldeliveryschedule':
        case 'quotessend':
        case 'quotesreceive':
        case 'quotesapproved':
        case 'quotesdeclined':
        case 'pendingloads':
        case 'delayedloads':
        case 'riskdelayedloads':
        case 'acceptedloads':
        case 'activeloads':
        case 'assignloads':
        case 'billedloads':
        case 'historyloads':
        case 'addresses':
        case 'latestOrder':
        case 'availablePalletsAtWarehouse':
        case 'availableProductsAtWarehouse':
        case 'availableSAPProducts':
        case 'getGraphActivity':
        case 'lanes':
        case 'wareHouses':
        case 'loads':
        case 'purchaseorders':
        case 'quotescreated':
        case 'reportListByCustomer':
        case 'reportListByCarrier':
        case 'reportListByLanes':
        case 'reportListByDate':
        case 'neworder':
        case 'carrier':
        case 'containers':
        case 'weekContainers':
        case 'newPurchaseOrders':
        case 'activePurchaseOrders':
        case 'closedPurchaseOrders':
        case 'monthContainers':
            return {
                ...state,
                [payload.model]: payload.data,
                loading: false,
                errors: {},
            };
        case ActionTypes.LOADING:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOADING_STOP:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default defaultReducer;
