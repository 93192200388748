import axios from 'axios';
import Cookies from 'js-cookie';

import { toastr, logout } from '@/utils/helpers';
// import { handleApiErrors } from './global';

// declare module 'axios' {
//   export interface AxiosRequestConfig {
//     // url: string;
//     // method: string;
//   }
// }
axios.interceptors.request.use(
    config => {
        if (typeof window !== 'undefined' && !config?.data?.silent) {
            document.body.style.pointerEvents = 'none';
        }
        return config;
    },
    error =>
        // Do something with request error
        Promise.reject(error),
);

axios.interceptors.response.use(
    response => {
        if (typeof window !== 'undefined') {
            document.body.style.pointerEvents = 'auto';
        }
        return response;
    },
    error => Promise.reject(error),
);

interface API {
    (
        url: string,
        method?: string,
        options?: {
            data?: unknown;
            headers?: {
                language?: string;
                'Content-Type': string;
            };
        },
    ): void;
}

interface AxiosRequestConfig {
    url: string;
    method: string;
    data?: any;
    timeout?: number;
    headers?: {
        language?: string;
        'Content-Type': string;
    };
}
export const api: API = async (apiEndpoint, method = 'GET', options = {}) => {
    const lang = Cookies.get('lang') || 'en';
    const token = Cookies.get('token') || 'en';
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${apiEndpoint}`;
    let config: AxiosRequestConfig = {
        url,
        method: method || 'GET',
        timeout: 1 * 60 * 1000,
    };

    if (!options.headers) {
        options.headers = {
            'Content-Type': 'application/json',
            language: lang,
        };
        if (token) {
            options.headers['Authorization'] = token;
        }
        config = { ...config, ...options };
    }
    try {
        const resp = await axios(config);
        return resp.data;
    } catch (e: any) {
        console.log('error', e);
        try {
            if (typeof window !== 'undefined') {
                document.body.style.pointerEvents = 'auto';
            }
            if (e?.response?.data) {
                if (e.response.status === 504) {
                    toastr('API services are currently Offline', 'warning');
                }
                if (e.response?.data?.errors?.message === 'jwt expired') {
                    logout();
                }
                return e.response.data;
            }
        } catch (error) {
            console.log(error);
            return error;
        }
    }
};
