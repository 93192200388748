import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import moment from 'moment';
import * as Yup from 'yup';

import { HANDLE_ERRORS } from '@/store/interfaces';
import { KEYPAIR } from '@/types/interfaces';
export const passcode = 'XY_m_1hj7595_1bgyklery1719' + '!@(*&@#%*&%^@&@@!()_%^&@*&!238&!@#^&%!*(&#%@#';
export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const genrateRendomString = () => {
    return Math.random().toString(36).substring(2, 7);
};
export const setAuthToken = (token: string | boolean) => {
    if (typeof token == 'string') {
        Cookies.set('token', token);
        axios.defaults.headers.common['Authorization'] = token;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};
export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const toastr = (title: string, icon: any) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        timerProgressBar: true,
        focusCancel: true,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    });
    Toast.fire({
        icon,
        title: title,
        timer: 3000,
        timerProgressBar: true,
        heightAuto: true,
        grow: 'row',
    });
};
export const confirmDialog = (title: string) => {
    return new Promise(resolve => {
        Swal.fire({
            title: '',
            text: title,
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            confirmButtonColor: '#3299cc',
            // denyButtonText: `Don't save`,
        })
            .then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    resolve(true);
                    // Swal.fire('Saved!', '', 'success');
                } else if (result.isDenied) {
                    resolve(false);
                    // Swal.fire('Changes are not saved', '', 'info');
                } else {
                    resolve(false);
                }
            })
            .catch(() => resolve(false));
    });
};

export const confirmSweetRequest = (title: string) => {
    return new Promise(resolve => {
        Swal.fire({
            title: title,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            confirmButtonColor: '#3299cc',
            showLoaderOnConfirm: true,
            preConfirm: response => {
                return resolve(response);
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
            .then(result => {
                if (result.isConfirmed) {
                    resolve(true);
                    // Swal.fire('Saved!', '', 'success');
                } else if (result.isDenied) {
                    resolve(false);
                    // Swal.fire('Changes are not saved', '', 'info');
                } else {
                    resolve(false);
                }
            })
            .catch(() => resolve(false));
    });
};

export const UploadFileSchema = () => {
    const FILE_SIZE = 1000;
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    return Yup.mixed()
        .test('fileSize', 'File Size is too large', value => value.size <= FILE_SIZE)
        .test('fileType', 'Unsupported File Format', value => SUPPORTED_FORMATS.includes(value.type));
};
export const capitalize = (title: string) => (title ? title.charAt(0).toUpperCase() + title.slice(1) : '');

export const trimAndLower = (data: string) => data.toLowerCase().trim();
export const handleErrors = (errors: HANDLE_ERRORS) => {
    let msg = '';
    if (errors) {
        if (typeof errors == 'object' && Object.keys(errors)?.length) {
            Object.entries(errors).map(([, value]) => {
                msg += value + '\n';
            });
        } else {
            if (typeof errors == 'string') {
                msg = errors;
                if (msg === 'Invalid auth token') {
                    logout();
                }
            }
        }
    }
    toastr(msg, 'error');
};
export const getFormattedGoogleAddress = (place: KEYPAIR, type = 'string') => {
    const data: KEYPAIR = {
        lat: '',
        lng: '',
        formattedAddress: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
    };
    if (place?.address_components) {
        const addressComponent = place?.address_components as any;
        for (const addr of addressComponent) {
            if (addr?.types?.[0]) {
                const typeofAddress = addr.types[0];
                if (typeofAddress === 'administrative_area_level_1') data['state'] = addr.long_name;
                if (typeofAddress === 'locality') data['city'] = addr.long_name;
                if (typeofAddress === 'country') data['country'] = addr.long_name;
                if (typeofAddress === 'postal_code') data['postalCode'] = addr.long_name;
            }
        }
        if (type === 'string') return `${data.city}, ${data.state} ${data.country}, ${data.zipcode || ''}`;
        return data;
    }
    return '';
};
export const addDistancetoLocations = (
    lat: number | string,
    lng: number | string,
    originLat: number | string,
    originLng: number | string,
    unit = 'k',
) => {
    try {
        lat = Number(lat);
        lng = Number(lng);
        originLat = Number(originLat);
        originLng = Number(originLng);
        // Distance in Radius
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(lat, lng),
            new google.maps.LatLng(originLat, originLng),
        );
        // Convert into Kilometer
        if (distance) return (distance * 0.001).toFixed(2);
    } catch (error) {
        return 0;
    }
    const radlat1 = (Math.PI * lat) / 180;
    const radlat2 = (Math.PI * originLat) / 180;
    // const radlon1 = (Math.PI * lng) / 180;
    // const radlon2 = (Math.PI * originLng) / 180;
    const theta = lng - originLng;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    // K = Kilometer
    if (unit == 'K') {
        dist = dist * 1.609344;
    }
    // N = Nautical Miles
    if (unit == 'N') {
        dist = dist * 0.8684;
    }
    return dist;
};
// calendar Week Number Show
export const getWeekNumber = (date: Date) => {
    const d = new Date(date) as any;
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1) as any;
    const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNumber;
};
export const formatDate = (date: string | Date, type?: string) => {
    if (!date) return '';
    if (!moment(date).isValid()) return '';
    return moment(date).format(type || 'lll');
};

export const getPDFFromBuffer = (bufferArray: number[], filename?: string) => {
    try {
        if (typeof window !== 'undefined') {
            document.body.style.pointerEvents = 'auto';
        }
        const blob = new Blob([new Uint8Array(bufferArray).buffer], { type: 'application/pdf' });
        // const win = window.open('', '_blank');
        // const URL = window.URL || window.webkitURL;
        // const dataUrl = URL.createObjectURL(blob);
        // if (win) win.location = dataUrl;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        if (link.download !== undefined) {
            link.setAttribute('href', url);
            link.setAttribute('target', '_blank');
            link.setAttribute('title', filename || 'Order Confirmation PDF');
            // link.setAttribute('download', filename || 'Order Confirmation PDF');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    } catch (error) {
        console.log('Blob Error', error);
    }
};
export const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    // eslint-disable-next-line no-useless-escape
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};
export const logout = () => {
    setAuthToken(false);
    Cookies.remove('token');
    Cookies.remove('rememberme');
    window.location.href = '/';
};

export const copyMessage = (message: string) => {
    navigator.clipboard.writeText(message);
};

/** @format */

export const encryptCodes = (content: string) => {
    const result = [];
    const passLen = passcode.length;
    for (let i = 0; i < content.length; i++) {
        const passOffset = i % passLen;
        const calAscii = content.charCodeAt(i) + passcode.charCodeAt(passOffset);
        result.push(calAscii);
    }
    return JSON.stringify(result);
};
export const decryptCodes = (content: string) => {
    const result = [];
    let str = '';
    const codesArr = JSON.parse(content);
    const passLen = passcode.length;
    for (let i = 0; i < codesArr.length; i++) {
        const passOffset = i % passLen;
        const calAscii = codesArr[i] - passcode.charCodeAt(passOffset);
        result.push(calAscii);
    }
    for (let i = 0; i < result.length; i++) {
        const ch = String.fromCharCode(result[i]);
        str += ch;
    }
    return str;
};

export const ordinal_suffix_of = (i: number) => {
    const j = i % 10;
    const k = i % 100;
    if (j == 1 && k != 11) {
        return i + 'st week';
    }
    if (j == 2 && k != 12) {
        return i + 'nd week';
    }
    if (j == 3 && k != 13) {
        return i + 'rd week';
    }
    return i + 'th week';
};
