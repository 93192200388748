import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Get Available Truckers for Load */
function* getAllDeliverySchedule(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;

        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/get-all-delivery-schedule`, 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({ type: 'alldeliveryschedule', payload: { model: 'alldeliveryschedule', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update User Profile Password */
function* updateLoadDateWithTrucker(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/update-trucker-calendar-date/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr(res.data, 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.DELIVERY_SCHEDULE_GET_DETAILS, withCallback(getAllDeliverySchedule as any));

    yield takeLatest(ActionTypes.DELIVERY_SCHEDULE_UPDATE_DATE, withCallback(updateLoadDateWithTrucker as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
