import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Create Fleet  User Load */
function* addLoad(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/load-manager/add-load', 'POST', { data: payload });
        if (res.status) {
            toastr('Load Successfully Added', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Remove Fleet  User Load */
function* removeLoad(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/remove-load/${payload?._id}`, 'DELETE');
        if (res.status) {
            // const body = {
            //   limit: state?.default?.loads?.limit,
            //   page: state?.default?.loads?.page,
            // };
            // yield put({
            //   type: ActionTypes.GET_DEFAULT_DATA_MODEL,
            //   payload: { pathname: 'load-manager/load-list', model: 'loads', ...body },
            // });
            toastr('Load Successfully Removed', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Get Load Detail by ID */
function* getLoadDetailById(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/load-detail/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
function* getLoadDetailsByloadId(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/load-detail-loadId/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* trackDetailByLoadId(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/track-detail/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* trackLocationByTruckerId(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/tracking-location/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Subload Load Detail by ID */
function* getSubloadLoadDetailById(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/subload-detail/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Fleet  User Address */
function* updateLoad(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/load-update/${payload?._id}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Load Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Load Detail */
function* updateLoadDetail(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/load-details-update/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Load Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Assign Load to Truck */
function* assignLoadToTruck(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/assign-load-to-truck`, 'POST', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Available Truckers for Load */
function* getAvailableTruckerForLoad(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/load-available-truckers`, 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({ type: 'availabletrucksforload', payload: { model: 'availabletrucksforload', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Available Truckers for Load */
function* getAllLoadsDetails(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/get-all-loads-details`, 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({ type: 'allloadsDetails', payload: { model: 'allloadsDetails', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Remove Fleet  User Load */
function* broadCastLoad(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const state: any = yield select();
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/broadcast-load/${payload?._id}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            const body = {
                limit: state?.default?.loads?.limit,
                page: state?.default?.loads?.page,
            };
            yield put({
                type: ActionTypes.GET_DEFAULT_DATA_MODEL,
                payload: { pathname: 'load-manager/load-list', model: 'loads', ...body },
            });
            toastr('Load Successfully Broadcasted', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Send Quote to Users */
function* sendQuoteToUsers(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const state: any = yield select();
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/send-quote-users/${payload?._id}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            const body = {
                limit: state?.default?.loads?.limit,
                page: state?.default?.loads?.page,
            };
            yield put({
                type: ActionTypes.GET_DEFAULT_DATA_MODEL,
                payload: { pathname: 'load-manager/load-list', model: 'loads', ...body },
            });
            toastr('Quote Successfully Send to Users', 'success');
            return res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Available Pallets at warehouse */
function* getAvailPallets(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/warehouse-manager/availability`, 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({
                type: 'availablePalletsAtWarehouse',
                payload: { model: 'availablePalletsAtWarehouse', data: res.data },
            });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* getAvailProducts(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/warehouse-manager/products`, 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({
                type: 'availableProductsAtWarehouse',
                payload: { model: 'availableProductsAtWarehouse', data: res.data },
            });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
function* getSAPProducts(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/integration-manager/get-sap-products`, 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({
                type: 'availableSAPProducts',
                payload: { model: 'availableSAPProducts', data: res.data },
            });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Get Address Default Address */
function* getDefaultAddress(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/address-manager/default-address`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.LOADS_ADD, withCallback(addLoad as any));
    yield takeLatest(ActionTypes.LOADS_REMOVE, withCallback(removeLoad as any));
    yield takeLatest(ActionTypes.LOADS_UPDATE, withCallback(updateLoad as any));
    yield takeLatest(ActionTypes.WAREHOUSE_GET_AVAILABLE_PALLETS, withCallback(getAvailPallets as any));
    yield takeLatest(ActionTypes.WAREHOUSE_GET_AVAILABLE_PRODUCTS, withCallback(getAvailProducts as any));
    yield takeLatest(ActionTypes.LOADS_GET_SAP_PRODUCTS, withCallback(getSAPProducts as any));
    yield takeLatest(ActionTypes.LOADS_GET_DEFAUlT_ADDRESSS, withCallback(getDefaultAddress as any));

    yield takeLatest(ActionTypes.LOADS_DETAIL_UPDATE, withCallback(updateLoadDetail as any));
    yield takeLatest(ActionTypes.LOADS_GET_LOAD_DETAIL, withCallback(getLoadDetailById as any));
    yield takeLatest(ActionTypes.LOADS_GET_LOAD_DETAIL_BY_LOADID, withCallback(getLoadDetailsByloadId as any));
    yield takeLatest(ActionTypes.TRACK_DETAIL_BY_LOAD_ID, withCallback(trackDetailByLoadId as any));
    yield takeLatest(ActionTypes.TRACK_LOCATION_TRUCKER_ID, withCallback(trackLocationByTruckerId as any));
    yield takeLatest(ActionTypes.LOADS_GET_SUBLOAD_DETAIL, withCallback(getSubloadLoadDetailById as any));
    yield takeLatest(ActionTypes.LOADS_ASSIGN_TRUCK, withCallback(assignLoadToTruck as any));
    yield takeLatest(ActionTypes.LOADS_AVAILABLE_TRUCKERS, withCallback(getAvailableTruckerForLoad as any));
    yield takeLatest(ActionTypes.LOADS_GET_LOAD_DETAILS, withCallback(getAllLoadsDetails as any));
    yield takeLatest(ActionTypes.LOADS_BROADCAST, withCallback(broadCastLoad as any));
    yield takeLatest(ActionTypes.LOADS_SEND_QUOTES_TO_USERS, withCallback(sendQuoteToUsers as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
