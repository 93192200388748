import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import Spinner from 'react-bootstrap/Spinner';

interface PROPS {
    color?: string;
    size?: any;
}
function ButtonLoader(props?: PROPS) {
    return (
        <div className="d-flex justify-content-center">
            <ThreeCircles
                height="30"
                width="30"
                color={props?.color || '#FFFFFF'}
                ariaLabel="three-circles-rotating"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
}

function SimpleLoader(props?: PROPS) {
    return <Spinner size={props?.size || 'md'} animation="border" variant="primary" />;
}
export const useLoading = () => {
    return { ButtonLoader, SimpleLoader };
};
