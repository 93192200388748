import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, setAuthToken, toastr } from '@/utils/helpers';

/* Login User */
function* loginUser(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/login', 'POST', { data: payload });
        if (res.status) {
            if (res?.data?.token) {
                toastr('Login Successfully', 'success');
                setAuthToken(res.data.token);
            }
            yield put({ type: 'SET_USER', payload: { model: 'user', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Register User */
function* registerUser(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/register', 'POST', { data: payload });
        if (res.status) {
            toastr('User Succesfully Registered. Please verify your Email', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Forgot User  Password*/
function* forgotPassWord(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/forgot-password', 'POST', { data: payload });
        if (res.status) {
            toastr(res.data, 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Reset User  Password*/
function* resetPassWord(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/user-manager/reset-password', 'POST', { data: payload });
        if (res.status) {
            toastr(res.data, 'success');
            yield put({ type: 'SET_USER', payload: { model: 'user', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Verify User  Email */
function* verifyEmail(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/user-manager/verify-email?token=${payload?.token as string}&email=${payload?.email as string}`,
        );
        if (res.status) {
            toastr(res.data, 'success');
            yield put({ type: 'SET_USER', payload: { model: 'user', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest('LOGIN', withCallback(loginUser as any));
    yield takeLatest('REGISTER', withCallback(registerUser as any));
    yield takeLatest('AUTH_FORGOT_PASSWORD', withCallback(forgotPassWord as any));
    yield takeLatest('AUTH_RESET_PASSWORD', withCallback(resetPassWord as any));
    yield takeLatest('AUTH_VERIFY_EMAIL', withCallback(verifyEmail as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
