import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Update User Profile Password */
function* addAccesorial(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/order-manager/update-accessorial', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr(res.data, 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Confirm Load By Fleet */
function* confirmLoadByFleet(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/order-manager/order-accept-by-fleet', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Order has been successfully Confirmed', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Order Assign By Fleet to Trucker */
function* orderAssignByFleetToTruck(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/order-manager/order-assign-by-fleet', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Order has been successfully Assigned', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Send Invoice to User */
function* sendInvoiceToUser(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, '/v1/api/order-manager/send-invoice', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Invoice sent successfully', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Send Invoice to User */
function* orderMarkedAsPaid(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/order-manager/rate-paid-order/${payload?.type}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Load Detail  Status*/
function* updateLoadDetailStatus(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/order-manager/update-orderdetail-status/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Order Status Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Update Load Detail  Reject*/
function* updateLoadDetailRejct(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/order-manager/reject-orderdetail/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Order Status Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Load Status */
function* updateLoadStatus(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/order-manager/update-order-status/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Order Status Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Order Confirmation PDF */
function* orderConfirmationPDF(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/order-manager/order-confirmation-pdf`, 'POST', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Invoice Order PDF */
function* invoiceOrderPDF(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/order-manager/invoice-order-confirmation-pdf`, 'POST', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Bill of Lading Order PDF */
function* billOfLadingOrderPDF(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/order-manager/bol-order-confirmation-pdf`, 'POST', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Fetch SAP Orders  */
function* getOrderSAP(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });

        const res: ReturnType<any> = yield call(api, `/v1/api/integration-manager/get-sap-orders`, 'GET');
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Order Reset */
function* orderReset(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/order-manager/reset-order`, 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Order Status Reset Successfully.', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Next Previous Order API */
function* nextPreviousOrder(action: ACTION): Generator<unknown> {
    const { payload } = action;
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/order-manager/next-previous-orders`, 'POST', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Order Detail */
function* getOrderDetail(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/order-manager/get-order-detail/${payload?.orderId}`,
            'GET',
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Load Detail  Status*/
function* updateOrderDetailStatus(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/order-manager/orderdetail-update-status/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Order Status Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.ORDERS_ACCEPT_ADD_ACCESORIAL, withCallback(addAccesorial as any));
    yield takeLatest(ActionTypes.ORDERS_CONFIRM_BY_FLEET, withCallback(confirmLoadByFleet as any));
    yield takeLatest(ActionTypes.ORDERS_ASSIGN_TO_TRUCK, withCallback(orderAssignByFleetToTruck as any));
    yield takeLatest(ActionTypes.ORDERS_DETAIL_STATUS_UPDATE, withCallback(updateLoadDetailStatus as any));
    yield takeLatest(ActionTypes.ORDERS_STATUS_UPDATE, withCallback(updateLoadStatus as any));
    yield takeLatest(ActionTypes.ORDERS_SEND_INVOICE, withCallback(sendInvoiceToUser as any));
    yield takeLatest(ActionTypes.ORDERS_MARK_PAID, withCallback(orderMarkedAsPaid as any));
    yield takeLatest(ActionTypes.ORDERS_CONFIRMATION_PDF, withCallback(orderConfirmationPDF as any));
    yield takeLatest(ActionTypes.ORDERS_INVOICE_ORDER_PDF, withCallback(invoiceOrderPDF as any));
    yield takeLatest(ActionTypes.ORDERS_BOL_ORDER_PDF, withCallback(billOfLadingOrderPDF as any));
    yield takeLatest(ActionTypes.ORDERS_DETAIL_REJECT, withCallback(updateLoadDetailRejct as any));
    yield takeLatest(ActionTypes.ORDERS_RESET, withCallback(orderReset as any));
    yield takeLatest(ActionTypes.ORDERS_NEXT_PREVIOUS, withCallback(nextPreviousOrder as any));

    yield takeLatest(ActionTypes.GET_SAP_ORDER, withCallback(getOrderSAP as any));

    yield takeLatest(ActionTypes.ORDERS_DETAIL_BY_ID, withCallback(getOrderDetail as any));
    yield takeLatest(ActionTypes.ORDERS_DETAIL_UPDATE_STATUS_PUBLIC, withCallback(updateOrderDetailStatus as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
