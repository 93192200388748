import { put, takeLatest, all, call, takeEvery } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors } from '@/utils/helpers';

/* Fetch Orders Count */
function* fetchOrdersCount(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/dashboard-manager/orders-count/${payload?.type}`);
        if (res.status) {
            // yield put({ type: ActionTypes.LOAD_DEFAULT_ADD_ALL_SUCCESS, payload: { model: 'truckers', data: res.data } });
            return res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Fetch Orders Count */
function* fetchTrucksCount(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/dashboard-manager/my-truker-count/${payload?.type}`);
        if (res.status) {
            // yield put({ type: ActionTypes.LOAD_DEFAULT_ADD_ALL_SUCCESS, payload: { model: 'truckers', data: res.data } });
            return res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Fetch Orders Count */
function* fetchTop5RatedTrucks(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/dashboard-manager/top-rated-trucks`);
        if (res.status) {
            yield put({
                type: 'top5ratedtrucks',
                payload: { model: 'top5ratedtrucks', data: res.data },
            });
            return res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* fetchLatestOrdersList(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/dashboard-manager/latest-orders-list`);
        if (res.status) {
            yield put({ type: 'latestOrder', payload: { model: 'latestOrder', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Fetch Trucks Count */
function* fetchTruckerNotDelivered(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/dashboard-manager/trucker-not-on-time`);
        if (res.status) {
            yield put({
                type: 'truckersnotontime',
                payload: { model: 'truckersnotontime', data: res.data },
            });
            return res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Fetch Trucks Count */
function* fetchRecentActivities(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/logs-manager/logs-list`);
        if (res.status) {
            yield put({
                type: 'recentactivities',
                payload: { model: 'recentactivities', data: res.data },
            });
            return res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Fetch Popular Routes */
function* fetchPopularRoutes(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/dashboard-manager/popular-routes`);
        if (res.status) {
            yield put({
                type: 'popularroutes',
                payload: { model: 'popularroutes', data: res.data },
            });
            return res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* getGraphActivity(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/order-manager/activity-graph`, 'POST', {
            data: payload,
        });
        if (res.status) {
            yield put({
                type: 'getGraphActivity',
                payload: { model: 'getGraphActivity', data: res.data },
            });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* defaultWatchers() {
    yield takeEvery(ActionTypes.DASHBOARD_FETCH_ORDERS_COUNT, withCallback(fetchOrdersCount));
    yield takeEvery(ActionTypes.DASHBOARD_FETCH_TRUCK_COUNT, withCallback(fetchTrucksCount));
    yield takeLatest(ActionTypes.GET_GRAPH_ACTIVITY, withCallback(getGraphActivity as any));
    yield takeLatest(ActionTypes.DASHBOARD_FETCH_TOP_RATED_TRUCKS, withCallback(fetchTop5RatedTrucks));
    yield takeLatest(ActionTypes.DASHBOARD_FETCH_TRUCKS_NOT_DELIVERED, withCallback(fetchTruckerNotDelivered));
    yield takeLatest(ActionTypes.DASHBOARD_FETCH_RECENT_ACTIVITIES, withCallback(fetchRecentActivities));
    yield takeLatest(ActionTypes.DASHBOARD_FETCH_LATEST_ORDERS_LIST, withCallback(fetchLatestOrdersList));
    yield takeLatest(ActionTypes.DASHBOARD_FETCH_POPULAR_ROUTES_LIST, withCallback(fetchPopularRoutes));
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
