import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Confirm Quote */
function* confirmQuote(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/confirm-quote/${payload?.quoteId}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            // yield put({
            //   type: ActionTypes.GET_DEFAULT_DATA_MODEL,
            //   payload: { pathname: 'favourite-manager/fav-list', model: 'favouriteUsers' },
            // });
            toastr('Quote is now confirmed. Please wait for Quote to be Approved', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Decline Quote */
function* declineQuote(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/decline-quote/${payload?.quoteId}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            // yield put({
            //   type: ActionTypes.GET_DEFAULT_DATA_MODEL,
            //   payload: { pathname: 'favourite-manager/fav-list', model: 'favouriteUsers' },
            // });
            toastr(typeof res.data === 'string' ? res.data : 'Quote is now declined. Thank You', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Approve Quote */
function* approveQuote(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/load-manager/approve-quote/${payload?.quoteId}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            // yield put({
            //   type: ActionTypes.GET_DEFAULT_DATA_MODEL,
            //   payload: { pathname: 'favourite-manager/fav-list', model: 'favouriteUsers' },
            // });
            toastr('Quote is now Approved', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Quote Detail */
function* getQuoteDetail(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/get-quote-detail/${payload?.quoteId}`,
            'GET',
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Quote Detail */
function* getQuoteDetailForAdmin(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/get-quote-detail-admin/${payload?.quoteId}`,
            'GET',
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Validate Quote Passcode */
function* validateQuotePasscode(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/validate-quote-passcode/${payload?.quoteId}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Send Comment for Quote */
function* sendQuoteComment(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/send-quote-comment/${payload?.quoteId}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Send Comment for Quote Sender */
function* sendQuoteCommentSender(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/send-quote-comment-sender/${payload?.quoteId}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Decline Quote */
function* cancelAllQuote(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/load-manager/cancel-all-quote/${payload?.loadId}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            // yield put({
            //   type: ActionTypes.GET_DEFAULT_DATA_MODEL,
            //   payload: { pathname: 'favourite-manager/fav-list', model: 'favouriteUsers' },
            // });
            toastr(typeof res.data === 'string' ? res.data : 'All Quotes are cancelled. Thank You', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.QUOTE_CONFIRMED, withCallback(confirmQuote as any));
    yield takeLatest(ActionTypes.QUOTE_DECLINED, withCallback(declineQuote as any));
    yield takeLatest(ActionTypes.QUOTE_ALL_CANCEL, withCallback(cancelAllQuote as any));
    yield takeLatest(ActionTypes.QUOTE_APPROVED, withCallback(approveQuote as any));
    yield takeLatest(ActionTypes.QUOTE_DETAIL_BY_ID, withCallback(getQuoteDetail as any));
    yield takeLatest(ActionTypes.QUOTE_DETAIL_BY_ID_FOR_ADMIN, withCallback(getQuoteDetailForAdmin as any));
    yield takeLatest(ActionTypes.QUOTE_VALIDATE_PASSCODE, withCallback(validateQuotePasscode as any));
    yield takeLatest(ActionTypes.QUOTE_SEND_COMMENT, withCallback(sendQuoteComment as any));
    yield takeLatest(ActionTypes.QUOTE_SEND_COMMENT_SENDER, withCallback(sendQuoteCommentSender as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
