import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Add Favourite */
function* addFavourite(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/favourite-manager/add-fav`, 'POST', {
            data: payload,
        });
        if (res.status) {
            // yield put({
            //   type: ActionTypes.GET_DEFAULT_DATA_MODEL,
            //   payload: { pathname: 'favourite-manager/fav-list', model: 'favouriteUsers' },
            // });
            toastr('User is now Favourite Successfully', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Remove Favourite */
function* removeFavourite(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/favourite-manager/remove-fav`, 'POST', {
            data: payload,
        });
        if (res.status) {
            // yield put({
            //   type: ActionTypes.GET_DEFAULT_DATA_MODEL,
            //   payload: { pathname: 'favourite-manager/fav-list', model: 'favouriteUsers' },
            // });
            toastr('User is now Unfavourite', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.FAVOURITES_ADD, withCallback(addFavourite as any));
    yield takeLatest(ActionTypes.FAVOURITES_REMOVE, withCallback(removeFavourite as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
