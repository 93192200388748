import { combineReducers } from 'redux';

import defaultState from './default';

import authReducer from '@/pages/Auth/authReducer';

export default combineReducers({
    default: defaultState,
    auth: authReducer,
});
