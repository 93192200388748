import React, { createContext, useState, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

import { KEYPAIR } from '@/types/interfaces';
import { useChatState } from '@/pages/Chat/reducer';
import { MESSAAGETYPE } from '@/pages/Chat/interface';

interface PROPS {
    children: JSX.Element | JSX.Element[];
}
interface SOCKETCONTEXTVALUE {
    message: '';
    sendMessage: (message: string) => void;
    messages: MESSAAGETYPE[];
    state: any;
    dispatch: React.Dispatch<{ type: string; data: KEYPAIR }>;
    fetchUsersList: () => void;
}
const SocketContext = createContext<SOCKETCONTEXTVALUE | null>(null);

const socket = io(process.env.REACT_APP_API as string);

export const ChatProvider = ({ children }: PROPS) => {
    const [messages, setmessages] = useState<MESSAAGETYPE[]>([]);
    const { state, dispatch } = useChatState();

    const getUserInfo = () => {
        try {
            const jwtDecodedToken = Cookies.get('token') as undefined | string;
            if (jwtDecodedToken) {
                // Non Null Assertion ! will remove undefined and null from a type without doing any explicit type checking
                const decoded: any = jwt_decode(jwtDecodedToken as string);
                if (decoded) return decoded;
            }
        } catch (error) {
            console.log('klg-36', error);
        }
    };
    const fetchUsersList = async () => {
        const user = getUserInfo();
        if (user && socket) socket.emit('requestUserList', user);
    };

    useEffect(() => {
        const user = getUserInfo();
        socket.on('connect', () => {
            if (user) {
                socket.emit('userjoined', user);
                // fetchUsersList();
            }
        });

        socket.on('disconnect', () => {
            console.log('klg-26', 'disconnect');
            socket.emit('userLeft', user);
        });

        socket.on('fetchAllUsersList', (payload: any) => {
            const user = getUserInfo();
            if (payload && payload instanceof Array)
                dispatch({
                    type: 'SET_DATA',
                    data: {
                        ...state,
                        allusers: payload,
                        userInfo: user,
                    },
                });
        });

        socket.on('useronline', (payload: any) => {
            if (state?.allusers?.length && payload?.email) {
                const users = state.allusers.map((user: { email: string }) => ({
                    ...user,
                    online: user.email === payload.email ? true : false,
                }));
                dispatch({
                    type: 'SET_DATA',
                    data: {
                        ...state,
                        allusers: users,
                    },
                });
            }
        });

        socket.on('me', (payload: string) => {
            console.log('me- Socket Connected', payload);
        });

        socket.on('messageReceived', (payload: any) => {
            const newMessages: MESSAAGETYPE[] = [
                ...messages,
                {
                    message: payload.message,
                    position: payload.position,
                },
            ];
            setmessages(newMessages);
        });

        socket.on('receivedUserMessages', (payload: any) => {
            if (user) {
                const newmessages = payload.map((msg: { sender: string; message: string }) => ({
                    position: msg.sender === user._id ? 'to' : 'from',
                    message: msg.message,
                }));
                setmessages(newmessages);
            }
        });

        socket.on('receiveNotification', (payload: any) => {
            if (payload && payload instanceof Object) {
                dispatch({
                    type: 'SET_DATA',
                    data: {
                        ...state,
                        notifications: payload.notifications,
                        count_notification: payload.count_notification,
                    },
                });
            }
        });

        socket.on('sapOrderSyncUpdate', (payload: any) => {
            if (payload && payload instanceof Object) {
                dispatch({
                    type: 'SET_DATA',
                    data: {
                        ...state,
                        sapOrderSyncUpdate: payload,
                    },
                });
            }
        });
        socket.on('sapPurchaseOrderSyncUpdate', (payload: any) => {
            if (payload && payload instanceof Object) {
                dispatch({
                    type: 'SET_DATA',
                    data: {
                        ...state,
                        sapPurchaseOrderSyncUpdate: payload,
                    },
                });
            }
        });
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('fetchAllUsersList');
            socket.off('useronline');
            socket.off('messageReceived');
            socket.off('receivedUserMessages');
            socket.off('receiveNotification');
            socket.off('sapOrderSyncUpdate');
            socket.off('sapPurchaseOrderSyncUpdate');
        };
    }, [socket, messages, state]);

    const sendMessage = (message: string) => {
        socket.emit('sendMessage', {
            id: socket.id,
            toUser: state.currentUser,
            fromUser: getUserInfo(),
            message: message,
            position: 'to',
        });

        const newMessages: MESSAAGETYPE[] = [
            ...messages,
            {
                message: message,
                position: 'to',
            },
        ];
        setmessages(newMessages);
    };

    useEffect(() => {
        const user = getUserInfo();
        if (user && state.currentUser) {
            socket.emit('getUserMessages', { sender: user._id, receiver: state.currentUser._id });
        }
    }, [state.currentUser]);

    const value: SOCKETCONTEXTVALUE = { message: '', sendMessage, messages, state, dispatch, fetchUsersList };
    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export function useChat() {
    const context = useContext(SocketContext);
    if (context === null) {
        throw new Error('useApp must be used within an AppProvider');
    }
    return context;
}
