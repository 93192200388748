// ...
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

// import { configureStore } from '@reduxjs/toolkit';

import rootSaga from './sagas';
import reducer from './reducers';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

// const store = createStore(reducer, initialState, applyMiddleware(...middleware));
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
sagaMiddleware.run(rootSaga);
// export const store = configureStore({
//   reducer: reducer,
//   middleware: [applyMiddleware(...middleware)],
// });

// const action = (type: any) => store.dispatch({ type });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
// rest unchanged
